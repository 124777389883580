// Override default variables before the import
$arcadia-primary: rgb(0, 64, 55);
$arcadia-secondary: rgb(14, 255, 133);
$arcadia-tan: rgb(249, 243, 232);

$primary: $arcadia-primary;
$secondary: $arcadia-secondary;
$light: $arcadia-tan;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

footer.watt-footer {
  background-color: $arcadia-primary;
  color: $arcadia-tan;

  span {
    opacity: 0.5;
  }
  a {
    color: $arcadia-tan;
    opacity: 0.5;
  }
  a:hover {
    opacity: unset;
  }

  border-top: 1px solid $secondary;
}
